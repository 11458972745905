// Service is used for http requests, retreiving the data
import axios from "axios";
import { toast } from "react-toastify";
import { LectureType } from "./lectureSlice";


let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/lectures"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/lectures";
}

// Get Lectures
const getLectures = async (
  token: string,
  groupID?: string
): Promise<LectureType[] | any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    console.log("groupID inside service: ", groupID);
    const url = groupID ? `${API_URL}/${groupID}` : API_URL;
    console.log("url: ", url);
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    console.log("error from LectureService: ", error);
    toast.error("Error getting lectures");
    throw error;
  }
};

const lockUnlockLecture = async (
  groupID: string,
  lectureID: number,
  isUnlocked: boolean,
  token: string
): Promise<LectureType | any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.put(
      `${API_URL}/${groupID}/${lectureID}`,
      isUnlocked,
      config
    );

    return data[0];
  } catch (error) {
    console.log("error from LectureService lockunlock: ", error);
    toast.error("Error updating lectures");
    return error;
  }
};

const lectureService = {
  getLectures,
  lockUnlockLecture,
};

export default lectureService;
