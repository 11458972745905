// Service is used for http requests, retreiving the data
import axios from "axios";
import { toast } from "react-toastify";
import { AssignmentType } from "./assignmentSlice";


let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/assignments"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/assignments";
}

// Login User
const getAssignments = async (
  token: string,
  groupID?: string
): Promise<AssignmentType[] | any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    console.log("groupID inside service: ", groupID);
    const url = groupID ? `${API_URL}/${groupID}` : API_URL;
    console.log("url: ", url);
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    console.log("error from AssignmentService: ", error);
    toast.error("Error getting Assignments");
    return error;
  }
};

const lockUnlockAssignment = async (
  groupID: string,
  assignmentID: number,
  isUnlocked: boolean,
  token: string
): Promise<AssignmentType | any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.put(
      `${API_URL}/${groupID}/${assignmentID}`,
      isUnlocked,
      config
    );

    return data[0];
  } catch (error) {
    console.log("error from assignmentService lockunlock: ", error);
    toast.error("Error updating assignments");
    return error;
  }
};

const authService = {
  getAssignments,
  lockUnlockAssignment,
};

export default authService;
