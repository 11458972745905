import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import assignmentReducer from "../features/assignments/assignmentSlice";
import authReducer from "../features/auth/authSlice";
import groupReducer from "../features/groups/groupSlice";
import lectureReducer from "../features/lectures/lectureSlice";
import menuReducer from "../features/menu/menuSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    lectures: lectureReducer,
    assignments: assignmentReducer,
    groups: groupReducer,
    menu: menuReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
