import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
    name: "menu",
    initialState: {
        activeItem: "",
    },
    reducers: {
        setActiveItem: (state, action) => {
        state.activeItem = action.payload;
        },
    },
});

export const { setActiveItem } = menuSlice.actions;

export default menuSlice.reducer;