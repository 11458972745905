import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Container, Divider, Header, Pagination, Progress, Segment } from "semantic-ui-react";
import { AppDispatch, RootState } from "../app/store";
import locked from "../components/locked";
import { getLectures } from "../features/lectures/lectureSlice";
import "../styles/lecture-page.scss";

const LecturePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { lectures, isLoading } = useSelector(
    (state: RootState) => state.lectures
  );
  const [activePage, setActivePage] = useState(1);
  const [videoDurations, setVideoDurations] = useState<string[]>([]);
  const location = useLocation();
  
  useEffect(() => {
    dispatch(getLectures({ groupID: user?.groupid }));
  }, [dispatch, user]);

  

  const currentLecture = useMemo(() => {
    return (
      lectures?.find((lecture) => lecture.lectureURL === location.pathname) || {
        id: 0,
        title: "",
        subHeading: "",
        image: "",
        lectureURL: "",
        isUnlocked: false,
        slides: "",
        videos: [],
      }
    );
  }, [lectures, location.pathname]);

  const getVideoDuration = (url: string): Promise<number> => {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.src = url;
    });
  };

  useEffect(() => {
    const fetchVideoDurations = async () => {
      if (currentLecture.videos.length > 0) {
        const promises = currentLecture.videos.map((video: { url: string; }) => getVideoDuration(video.url));
        const results = await Promise.all(promises);
        setVideoDurations(results.map(duration => duration.toFixed(2)));
      }
    };

    fetchVideoDurations();
  }, [currentLecture.videos]);

  const { title, slides, subHeading, videos } = currentLecture;

  const hasAccess = useMemo(() => user?.role === "admin" || user?.role === "facilitator", [user]);

  // Update the lecture lock condition to include role check
  const isLectureLocked = useMemo(() => !currentLecture.isUnlocked && !hasAccess, [currentLecture, hasAccess]);

  if (isLectureLocked && !isLoading) {
    return locked("lecture");
  }

  const preventRightClick = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  const videoShow = videos[activePage - 1]

  const formatDuration = (duration: string) => {
    const totalSeconds = parseFloat(duration);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return `${minutes}m ${seconds}s`;
  };
  
  const videoDurationText = videoDurations[activePage - 1] ? formatDuration(videoDurations[activePage - 1]) : "Loading...";

  const formatTotalDuration = (durations: string[]): string => {
    const totalDurationInSeconds = durations.reduce((acc, duration) => acc + parseFloat(duration), 0);
    const totalHours = Math.floor(totalDurationInSeconds / 3600);
    const totalMinutes = Math.floor((totalDurationInSeconds % 3600) / 60);
    const totalSeconds = totalDurationInSeconds % 60;

    const roundedMinutes = totalMinutes + Math.round(totalSeconds / 60);

    return totalHours > 0 ? `${totalHours}h ${roundedMinutes}m` : `${roundedMinutes}m`;
  };
  
  const totalDuration = videoDurations.length > 0 ? formatTotalDuration(videoDurations) : "Loading..."; 

  return (
    <Container>
      <Segment loading={isLoading} size="massive">
        {
          currentLecture && (
            <>
              <Header style={{ color: "#146c94" }} size="large" textAlign="center">
                {title}
                <Header.Subheader>{subHeading} - Lecture time: {totalDuration}</Header.Subheader>
              </Header>
              <Segment basic>
                {
                  videoShow && (
                    <>
                      <Container>
                        <Header as="h2" textAlign="center">
                          {videoShow.name} - {videoDurationText}
                        </Header>
                        <Progress size="medium" value={activePage} total={videos.length} progress="ratio" indicating success={activePage === videos.length}/>
                        <video
                          controls
                          src={videoShow.url}
                          controlsList="nodownload"
                          onContextMenu={preventRightClick}
                          style={{ width: "100%" }}
                        />
                      </Container>
                    </>
                  )
                }
                 
                <Pagination 
                  fluid activePage={activePage} 
                  lastItem={null} 
                  firstItem={null}  
                  totalPages={videos.length} 
                  onPageChange={(_, { activePage }) => setActivePage(activePage as number)}
                />
                <Divider hidden />
                <Button size="large" fluid color="blue" onClick={() => window.open(slides, "_blank")} disabled={!slides}> View Excerpts </Button>
              </Segment>
            </>
          )
        }

      </Segment>
    </Container>
  );
};
export default LecturePage;
