import { Link } from "react-router-dom";
import { Button, Container, Header, Icon, Segment } from "semantic-ui-react";

const locked = (message: string) => {
  return (
    <Container textAlign="center">
      <Segment size="huge" placeholder>
        <Header as="h2" icon>
          <Icon name="lock" />
          The {message} you are trying to access is not yet unlocked. Please wait until the facilitator unlocks this {message}. 
        </Header>
        <Segment.Inline>
          <Button size="large" as={Link} to="/lectures" primary> Go back to Lectures </Button>
        </Segment.Inline>
      </Segment>
    </Container>
  );
};
export default locked;
