import axios from "axios";
import { toast } from "react-toastify";
import { GroupType } from "./groupSlice";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/groups/"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/groups/";
}

// GET all groups
const getAllGroups = async (token: string): Promise<GroupType[] | any> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await axios.get(API_URL, config);

  if (data.message) {
    toast.error(data.message);
  }
  return data;
};

const memberService = {
  getAllGroups,
};

export default memberService;
