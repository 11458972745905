import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card } from "semantic-ui-react";
import { AppDispatch, RootState } from "../app/store";
import {
  AssignmentType,
  lockUnlockAssignment,
} from "../features/assignments/assignmentSlice";
import { UserType } from "../features/auth/authSlice";
import "../styles/card.scss";

export interface CardProps {
  key: string;
  assignment: AssignmentType;
  user: UserType;
}

const AssignmentCard = (props: CardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector((state: RootState) => state.assignments);

  if (!props.assignment) return <h2>Error Occurred</h2>;
  if (props.assignment.message) return <h2>Sorry assignments not available. Please try agian later</h2>;
  
  const { id, title, links, content, isUnlocked, groupID } = props.assignment;
  const user = props.user;

  const onClick = () => {
    if (isLoading) return;
    dispatch(
      lockUnlockAssignment({
        groupID,
        assignmentID: id,
        isUnlocked: !isUnlocked,
      })
    );
  };

  return (
    <>
        {isUnlocked ? (
          <Card raised>
            {user && user.role === 'admin' && (
              <Button disabled={id === 6} color="red" onClick={onClick}>
                Lock
              </Button>
            )}
            <Card.Content>
              <Card.Header>{title}</Card.Header>
              <Card.Description>
                {
                  content.map((info, index) =>{
                    if(info.text){
                      return <ReactMarkdown key={index}>{info.text}</ReactMarkdown>
                    } else {
                      return <p key={index}>No content available</p>
                    }
                  
                  })
                }
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              {
                links.map((link, index) => {
                  return (<Button type="button" style={{ marginTop: "5px" }} size="large" fluid key={index} as="a" href={link.url} target="_blank" content={link.name} color="blue" />);
                })
              }
            </Card.Content>
          </Card>
        ) : (
          <Card raised>
            {user && user.role === 'admin' && (
              <Button disabled={id === 6} color="green" onClick={onClick}>
                Unlock
              </Button>
            )}
            <Card.Content>
              <Card.Header>{title}</Card.Header>
              <Card.Description>Not yet Available</Card.Description>
            </Card.Content>
          </Card>
        )}
      </>
  );
};
export default AssignmentCard;
