import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { RootState } from "../app/store";

const PrivateRoutes = () => {
    const { user, isError } = useSelector((state: RootState) => state.auth);
    const location = useLocation();

    if (isError) {
        return <Navigate to="/login" replace />;
    }

    return user ? <Outlet /> : <Navigate to={`/login?redirect=${location.pathname}`} replace />;

};

export default PrivateRoutes;