import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, Container, Divider, Dropdown, Header, Segment } from "semantic-ui-react";
import { AppDispatch, RootState } from "../app/store";
import AssignmentCard from "../components/AssignmentCard";
import { getAssignments, reset } from "../features/assignments/assignmentSlice";
import { GroupType, getAllGroups } from "../features/groups/groupSlice";
import "../styles/assignments.scss";

const Assignments = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.auth);

  const { assignments, isLoading, isError, message } = useSelector(
    (state: RootState) => state.assignments
  );

  const { groupsArr } = useSelector((state: RootState) => state.groups);

  const [groupID, setGroupID] = useState<string | undefined>();

  const fetchAllGroups = useCallback(() => {
    if (user && (user.role === "admin" || user.role === "facilitator") && !groupsArr) {
      dispatch(getAllGroups());
    }
  }, [user, groupsArr, dispatch]);

  const fetchAssignmentsForGroup = useCallback(() => {
    if (groupID !== undefined) {
      dispatch(getAssignments({ groupID }));
    }
  }, [groupID, dispatch]);

  const fetchAssignmentsForUser = useCallback(() => {
    if (user && user.role !== "admin" && user.role !== "facilitator") {
      dispatch(getAssignments({}));
    }
  }, [user, dispatch]);

  //useEffect to call the above fetch functions
  useEffect(() => {
    if (isError) {
      toast.error(message);
      return;
    }

    fetchAllGroups();
    fetchAssignmentsForGroup();
    fetchAssignmentsForUser();
  }, [user, groupsArr, groupID, isError, message, fetchAllGroups, fetchAssignmentsForUser, fetchAssignmentsForGroup]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  //sort the groups
  const groupsCopy = groupsArr ? [...groupsArr].sort((a, b) => a.groupname.localeCompare(b.groupname)) : [];

  const { role } = user ? user : { role: null };
  
  return (
    <Container>
      <Segment fluid loading={isLoading} size="massive">
        <Header style={{ color: "#146c94" }} as="h1" textAlign="center"> Assignments </Header>
        <>
          {
            (role === "admin" || role === "facilitator") ? (
              <Segment basic>
                <p> Select a group to view lectures </p>
                <Dropdown
                  placeholder="Select Group"
                  fluid
                  selection
                  clearable
                  value={groupID}
                  options={groupsCopy.map((group: GroupType) => ({
                    key: group.id,
                    text: group.groupname.charAt(0).toUpperCase() + group.groupname.slice(1),
                    value: group.id,
                  }))}
                  onChange={(_, { value }) => {
                    setGroupID(value as string| undefined);
                    if (value) {
                      dispatch(getAssignments({ groupID: value as string }));
                    } else {
                      dispatch(reset());
                    }
                  }}
                />
              </Segment>
            ) : (
              <Header as="h2" textAlign="center"> Assigments will be available here after each meeting </Header>
            )
          }
          <Divider hidden />
          <Segment basic size="huge">
            <Card.Group stackable centered itemsPerRow={2}>
              {
                user && assignments !== null && assignments.length > 0 && (
                  assignments.map((assignment) => (
                    <AssignmentCard key={assignment.title} assignment={assignment} user={user}
                    />
                  ))
                )
              }
            </Card.Group>
          </Segment>
        </>
      </Segment>
    </Container>
  );
};
export default Assignments;
