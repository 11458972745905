import { group } from "console";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, Container, Divider, Dropdown, Header, Segment } from "semantic-ui-react";
import { AppDispatch, RootState } from "../app/store";
import LectureCard from "../components/LectureCard";
import { GroupType, getAllGroups } from "../features/groups/groupSlice";
import { getLectures, reset } from "../features/lectures/lectureSlice";
import "../styles/lectures.scss";

const Lectures = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { lectures, isLoading, isError, message } = useSelector(
    (state: RootState) => state.lectures
  );
  const { groupsArr } = useSelector((state: RootState) => state.groups);

  const [groupID, setGroupID] = useState<string | undefined>();

  const fetchAllGroups = useCallback(() => {
    if (user &&(user.role === "admin" || user.role === "facilitator") && !groupsArr) {
      dispatch(getAllGroups());
    }
  }, [user, groupsArr, dispatch]);

  const fetchLecturesForGroup = useCallback(() => {
    if (groupID !== undefined) {
      dispatch(getLectures({ groupID }));
    }
  }, [groupID, dispatch]);

  const fetchLecturesForUser = useCallback(() => {
    if (user && user.role !== "admin" && user.role !== "facilitator") {
      dispatch(getLectures({}));
    }
  }, [user, dispatch]);

  console.log("lectures: ", lectures);

  //useEffect to call the above fetch functions
  useEffect(() => {
    if (isError) {
      toast.error(message);
      return;
    }
    fetchAllGroups();
    fetchLecturesForGroup();
    fetchLecturesForUser();
  }, [user, groupsArr, groupID, isError, message, fetchAllGroups, fetchLecturesForUser, fetchLecturesForGroup]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  //sort the groups
  const groupsCopy = groupsArr ? [...groupsArr].sort((a, b) => a.groupname.localeCompare(b.groupname)) : [];

  const { role } = user ? user : { role: null };
  
  return (
    <Container>
      <Segment loading={isLoading} size="massive">
        <Header style={{ color: "#146c94" }} as="h1" textAlign="center"> Lectures </Header>
        <>
          {
            (role === "admin" || role === "facilitator") ? (
              <Segment basic>
                <p> Select a group to view lectures </p>
                <Dropdown
                  placeholder="Select Group"
                  fluid
                  selection
                  clearable
                  value={groupID}
                  options={groupsCopy.map((group: GroupType) => ({
                    key: group.id,
                    text: group.groupname.charAt(0).toUpperCase() + group.groupname.slice(1),
                    value: group.id,
                  }))}
                  onChange={(_, { value }) => {
                    setGroupID(value as string| undefined);
                    if (value) {
                      dispatch(getLectures({ groupID: value as string }));
                    } else {
                      dispatch(reset());
                    }
                  }}
                />
              </Segment>
            ) : (
              <Header as="h2" textAlign="center"> Lectures will be available here after each meeting </Header>
            )
          }
          <Divider hidden />
          <Segment basic size="huge">
            <Card.Group stackable centered itemsPerRow={3}>
              {
                user && lectures !== null && lectures.length > 0 && (
                  lectures.map((lecture) => (
                    <LectureCard key={lecture.title} lecture={lecture} user={user} />
                  ))
                )
              }
            </Card.Group>
          </Segment>
        </>
      </Segment>
    </Container>
  );
};
export default Lectures;
