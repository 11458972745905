// Service is used for http requests, retreiving the data
import axios from "axios";
import { toast } from "react-toastify";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/";
}

interface UserType {
  id: number;
  username: string;
  name: string;
  lastname: string;
  role: string;
  groupid: string;
  token: string;
  message: string;
}

// Login User
const login = async (userData: object): Promise<UserType | any> => {
  try {
    const { data } = await axios.post(API_URL + "login", userData);
    if (data.username) {
      localStorage.setItem("user", JSON.stringify(data));
      return data;
    }
    return data.message;
  } catch (error) {
    console.log("error from AuthService: ", error);
    throw error;
  }
};

const googleLogin = async (code: string): Promise<UserType | any> => {
  try {
    const { data } = await axios.post(API_URL + "google/login", { code });
    if (data.username) {
      localStorage.setItem("user", JSON.stringify(data));
    }
    return data;
  } catch (error) {
    console.log("error from AuthService: ", error);
    throw error;
  }
}


//logout CHANGE THIS TO HTTP ONLY COOKIE
const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  login,
  logout,
  googleLogin,
};

export default authService;
