import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Divider, Grid, Header, Image, List, Segment } from "semantic-ui-react";
import { AppDispatch, RootState } from "../app/store";
import bookletImage from "../assets/bookletImage.png";
import oyp from "../assets/oyp.jpeg";
import Spinner from "../components/Spinner";
import {
  AssignmentType,
  getAssignments,
} from "../features/assignments/assignmentSlice";
import { LectureType, getLectures } from "../features/lectures/lectureSlice";
import "../styles/home.scss";

const booklet = "https://drive.google.com/file/d/1AWL6EwSmmtAF9BREwqqW0-t_XpCnVh7I/view";

const Home = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user, isLoading, isError, message } = useSelector(
    (state: RootState) => state.auth
  );

  const { lectures } = useSelector((state: RootState) => state.lectures);
  const { assignments } = useSelector((state: RootState) => state.assignments);

  const [latestLecture, setLatestLecture] = useState<LectureType | null>(null);
  const [latestAssignment, setLatestAssignment] = useState<AssignmentType | null>(null);

  const fetchAssignmentsForUser = useCallback(() => {
    if (user && user.role !== "admin" && user.role !== "facilitator") {
      dispatch(getAssignments({}));
    }
  }, [user, dispatch]);

  const fetchLecturesForUser = useCallback(() => {
    if (user && user.role !== "admin" && user.role !== "facilitator") {
      dispatch(getLectures({}));
    }
  }, [user, dispatch]);

  //initial load to boot up server if asleep
  // useEffect(() => {
  //   fetch("https://course-2023-backend-3yvuhaorjq-uc.a.run.app", {
  //     method: "GET",
  //     headers: { "Content-Type": "application/json" },
  //   })
  //     .then((res) => {
  //       if (!res.ok) {
  //         toast.error("Server is down, please try again later");
  //       }
  //       return res.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message);
      return;
    }

    // if (user.role !== "admin" && user.role !== "facilitator") {
    //   dispatch(getLectures({}));
    //   // dispatch(getAssignments({}));
    // }
    fetchLecturesForUser();
    fetchAssignmentsForUser();
  }, [isError, message, fetchLecturesForUser, fetchAssignmentsForUser]);

  useEffect(() => {
    if (lectures) {
      const latestUnlockedLecture = [...lectures]
        .reverse()
        .find((lecture) => !lecture.message && lecture.isUnlocked);

      if (latestUnlockedLecture) {
        setLatestLecture(latestUnlockedLecture);
      }
    }
    if (assignments) {
      const latestUnlockedAssignment = [...assignments]
        .reverse()
        .find((assignment) => !assignment.message && assignment.isUnlocked);

      if (latestUnlockedAssignment) {
        setLatestAssignment(latestUnlockedAssignment);
      }
    }
  }, [lectures, assignments, user]);

  const { role } = user ? user : { role: null };

  if (isLoading) return <Spinner />;

  return (
    <div className="appContainer">
      <main className="body-container">
        <div className="home">
          {role !== "admin" && role !== "facilitator" && (
            <>
              <Segment padded size="massive" textAlign="left">
                <Header style={{ color: "#146c94" }} as="h2" size="medium" textAlign="left">Updates</Header>
                <List bulleted>
                  {
                    latestLecture && (
                      <List.Item>
                        <Link to={latestLecture.lectureURL}>{latestLecture.title}</Link>
                      </List.Item>
                    )
                  }
                  {
                    latestAssignment && (
                      <List.Item>
                        <Link to={`/assignments/`}>{latestAssignment.title}</Link>
                      </List.Item>
                    )
                  }
                  {
                    !latestLecture && !latestAssignment && (
                      <>
                      <List.Item>Welcome to the FL2F Workshop</List.Item>
                      <List.Item> Updates will appear as lectures and assignments become available </List.Item>
                      </>
                    )
                  }
                </List>
              </Segment>
            </>
          )}

          <Segment padded size="huge" basic className="course">
            <Header style={{ color: "#146c94" }} as="h2" size="large" textAlign="center">The Workshop</Header>
            <Divider hidden section />
            <Grid container >
              <Grid.Column width={5}>
                <Image as="a" rel="noopener noreferrer" rounded target="_blank" href="https://www.ucalgary.ca/labs/integrated-intelligent-sensing/dr-orly-yadid-pecht"  src={oyp} alt="Professor Yadid-Pecht" size="medium"/>
              </Grid.Column>
              <Grid.Column width={11}>
                <Header style={{ color: "#146c94" }} as="a" rel="noopener noreferrer" href="https://www.ucalgary.ca/labs/integrated-intelligent-sensing/dr-orly-yadid-pecht" size="small">Dr. Orly Yadid-Pecht</Header>
                <Divider hidden />
                <p>
                  From Lab 2 Fulfillment (FL2F) is a purpose-driven workshop that
                  teaches women in science, engineering, technology, and math (STEM)
                  and Medicine how to transform their research & discoveries into
                  products and companies. From Lab 2 Fulfillment was created by Dr.
                  Yadid-Pecht to guide women in STEM and Medicine towards
                  commercialization. Small interactive sessions alongside one-on-one
                  coaching cultivate a supportive space to ideate and pivot research
                  into businesses. The process that Dr. Yadid-Pecht has designed
                  helps women in STEM and Medicine make their scientific innovations
                  more impactful by showing them how to overcome hurdles, find
                  supporters, and build their strategic plans and alliances.
                </p>
              </Grid.Column>
            </Grid>
          </Segment>

          <Segment basic size="huge">
            <Header style={{ color: "#146c94" }} as="h2" size="large" textAlign="center" >Our Apps</Header>
            <Divider hidden section />
            <Grid  centered textAlign="center" >
              <Grid.Row>
                <Grid.Column width={8}>
                    <Button fluid size="massive" as={Link} to="https://meetings.fl2f.ca"  color="blue" style={{ backgroundColor: "#146c94" }} rel="noopener noreferrer" target="_blank">Meeting Scheduler</Button>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Button fluid size="massive" as={Link} to="https://peer-assessment.fl2f.ca/" color="blue" style={{ backgroundColor: "#146c94" }} rel="noopener noreferrer" target="_blank">Peer Assessment</Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                    <Button fluid size="massive" as={Link} to="https://investment.fl2f.ca" color="blue" style={{ backgroundColor: "#146c94" }}  rel="noopener noreferrer" target="_blank">Investment Panel</Button>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Button fluid size="massive" as={Link} to="https://user-management.fl2f.ca" color="blue" style={{ backgroundColor: "#146c94" }} rel="noopener noreferrer" target="_blank">Picture Upload</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment basic size="huge">
            <Header style={{ color: "#146c94" }} as="h2" size="large" textAlign="center" >Course Booklet</Header>
            <Divider hidden />
            <Grid centered>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Image as="a" rel="noopener noreferrer" target="_blank" href={booklet} src={bookletImage} alt="Course Material Booklet" size="medium"/>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Button fluid size="massive" as="a" rel="noopener noreferrer" target="_blank" href={booklet} color="blue" style={{ backgroundColor: "#146c94" }}>Download</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
                

            {/* <div className="flex">
              <a
                href={booklet}
                target="_blank"
                className="image"
                data-testid="booklet-image-link" rel="noreferrer"
              >
                <img
                  src={bookletImage}
                  alt="course material booklet"
                  loading="lazy"
                />
              </a>
              <a href={booklet} target="_blank" data-testid="download-link" rel="noreferrer">
                <button className="btn btn-blue">Download</button>
              </a>
            </div> */}
          </Segment>
        </div>
      </main>
    </div>
  );
};
export default Home;