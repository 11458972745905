import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import 'semantic-ui-css/semantic.min.css';
import App from "./App";
import { store } from "./app/store";
import "./styles/global.scss";

const container = document.getElementById("root")!;
const root = createRoot(container);

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

if (!clientId) {
  throw new Error("REACT_APP_GOOGLE_CLIENT_ID is not set in the environment variables.");
}

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
