import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import groupService from "./groupService";

export interface GroupType {
  id: string;
  groupname: string;
  grouprole: string;
}

interface GroupState {
  groupsArr: GroupType[] | null;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  message: string;
}

const initialState: GroupState = {
  groupsArr: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// GET All Traits for the group
export const getAllGroups = createAsyncThunk<
  GroupType[], //Return type
  void, // Thunk Argument
  { rejectValue: string; state: RootState } // ThunkAPIConfig
>("groups/getAll", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user?.token;
    if (!token) return thunkAPI.rejectWithValue("No token provided");
    return await groupService.getAllGroups(token);
  } catch (error: any) {
    const message = error.response.data.message || error.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder

      //Get All groups
      .addCase(getAllGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllGroups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = "";
        state.groupsArr = action.payload;
      })
      .addCase(getAllGroups.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message =
          action.payload ?? "An error occurred in the Group Slice";
        state.groupsArr = [];
      });
  },
});

export const { reset } = groupSlice.actions;

export default groupSlice.reducer;
