import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, Image } from "semantic-ui-react";
import { AppDispatch, RootState } from "../app/store";
import { UserType } from "../features/auth/authSlice";
import {
  LectureType,
  lockUnlockLecture,
} from "../features/lectures/lectureSlice";
import "../styles/card.scss";

export interface CardProps {
  key: string;
  lecture: LectureType;
  user: UserType;
}

const LectureCard = (props: CardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading } = useSelector((state: RootState) => state.lectures);

  if (props.lecture) {
    if (props.lecture.message) {
      return <h2>Sorry lectures not available. Please try again later</h2>;
    }

    const { id, title, subHeading, image, lectureURL, isUnlocked, groupID } = props.lecture;
    const user = props.user;

    const onClick = () => {
      if (isLoading) return;
      dispatch(
        lockUnlockLecture({ groupID, lectureID: id, isUnlocked: !isUnlocked })
      );
    };

    return (
      <>
        {lectureURL && isUnlocked ? (
          <Card raised as={Link} to={lectureURL}>
            {user && user.role === 'admin' && (
              <Button disabled={id === 6} color="red" onClick={onClick}>
                Lock
              </Button>
            )}
            <Image src={image} alt="" role="presentation" loading="lazy" />
            <Card.Content>
              <Card.Header>{title}</Card.Header>
              <Card.Description>{subHeading}</Card.Description>
            </Card.Content>
            <Card.Content extra>
              <p>[Available]</p>
            </Card.Content>
          </Card>
        ) : (
          <Card raised>
            {user && user.role === 'admin' && (
              <Button disabled={id === 6} color="green" onClick={onClick}>
                Unlock
              </Button>
            )}
            <Image src={image} size="large" role="presentation" loading="lazy" />
            <Card.Content>
              <Card.Header>{title}</Card.Header>
              <Card.Description>{subHeading}</Card.Description>
            </Card.Content>
            <Card.Content extra>
              {!title.includes("6") && (
                  <p>[Not Yet Available]</p>
              )}
            </Card.Content>
          </Card>
        )}
      </>
    );
  } else {
    return <h2>Error Occurred</h2>;
  }
};
export default LectureCard;
