import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import lectureService from "./lectureService";

export interface VideoType {
  id: number;
  title: string;
  url: string;
  name: string;
}

export interface LectureType {
  id: number;
  title: string;
  subHeading: string;
  image: string;
  lectureURL: string;
  isUnlocked: boolean;
  groupID: string;
  slides?: string;
  videos: VideoType[];
  message?: string;
}

interface LectureState {
  lectures: LectureType[] | null;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  message: string;
}

const initialState: LectureState = {
  lectures: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

// get all lectures
export const getLectures = createAsyncThunk<
  LectureType[], //Return type
  { groupID?: string }, // Thunk Argument
  { rejectValue: string; state: RootState } // ThunkAPIConfig
>("lectures/get", async (arg, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user?.token;
    if (!token) return thunkAPI.rejectWithValue("No token provided");
    return await lectureService.getLectures(token, arg.groupID);
  } catch (error: any) {
    const message = error.response?.data?.message || error.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const lockUnlockLecture = createAsyncThunk<
  LectureType, //Return type
  { groupID: string; lectureID: number; isUnlocked: boolean }, // thunk argument
  { rejectValue: string; state: RootState } // thunkapiconfig
>(
  "lectures/lockUnlock",
  async ({ groupID, lectureID, isUnlocked }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user?.token;
      if (!token) return thunkAPI.rejectWithValue("No token provided");
      return await lectureService.lockUnlockLecture(
        groupID,
        lectureID,
        isUnlocked,
        token
      );
    } catch (error: any) {
      const message = error.response?.data?.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const lectureSlice = createSlice({
  name: "lectures",
  initialState,
  // for syncronous functions
  reducers: {
    // reset to initial state
    reset: (state) => {
      state.lectures = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  // for async functions
  extraReducers: (builder) => {
    builder

      .addCase(getLectures.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLectures.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.lectures = action.payload;
      })
      .addCase(getLectures.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message =
          action.payload ?? "An error occurred in the Lecture Slice";
      })
      .addCase(lockUnlockLecture.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lockUnlockLecture.fulfilled, (state, action) => {
        const updatedLecture = action.payload;
        state.isLoading = false;
        state.isSuccess = true;
        if (state.lectures) {
          state.lectures[updatedLecture.id - 1] = updatedLecture;
        }
      })
      .addCase(lockUnlockLecture.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message =
          action.payload ?? "An error occurred in the Lecture Slice";
      });
  },
});

export const { reset } = lectureSlice.actions;

export default lectureSlice.reducer;
