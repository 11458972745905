import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image, Menu } from "semantic-ui-react";
import { RootState } from "../app/store";
import logo from "../assets/FL2F-logo.png";
import { logout, reset } from "../features/auth/authSlice";
import { setActiveItem } from "../features/menu/menuSlice";


const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { activeItem } = useSelector((state: RootState) => state.menu);

  const handleActiveItem = (e: any, { name }: any) => dispatch(setActiveItem(name));

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/login");
  };

  if (location.pathname === "/login") return null;

  return (
    <Menu size="huge">
      <Menu.Item as={Link} to="/" onClick={handleActiveItem} >
        <Image src={logo} size="small" />
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item as={Link} to="/lectures" name="Lectures" active={activeItem === "Lectures"} onClick={handleActiveItem}>
          Lectures
        </Menu.Item>
        <Menu.Item as={Link} to="/assignments" name="Assignments" active={activeItem === "Assignments"} onClick={handleActiveItem} >
          Assignments
        </Menu.Item>
        <Menu.Item name="Logout" onClick={onLogout}>
          Logout
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};
export default Navbar;
