import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import assignmentService from "./assignmentService";

export interface Link {
  url: string;
  name: string;
}

export interface Content {
  text?: string;
}

export interface AssignmentType {
  id: number;
  title: string;
  isUnlocked: boolean;
  links: Link[];
  content: Content[];
  groupID: string;
  message?: string;
}

interface AssignmentState {
  assignments: AssignmentType[] | null;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  message: string;
}

const initialState: AssignmentState = {
  assignments: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

// get all assignments
export const getAssignments = createAsyncThunk<
  AssignmentType[], //Return type
  { groupID?: string }, // Thunk Argument
  { rejectValue: string; state: RootState } // ThunkAPIConfig
>("assignments/get", async (arg, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user?.token;
    if (!token) return thunkAPI.rejectWithValue("No token provided");
    return await assignmentService.getAssignments(token, arg.groupID);
  } catch (error: any) {
    const message = error.response?.data?.message || error.message;
    return thunkAPI.rejectWithValue(message);
  }
});

export const lockUnlockAssignment = createAsyncThunk<
  AssignmentType, //Return type
  { groupID: string; assignmentID: number; isUnlocked: boolean }, // thunk argument
  { rejectValue: string; state: RootState } // thunkapiconfig
>(
  "assignments/lockUnlock",
  async ({ groupID, assignmentID, isUnlocked }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user?.token;
      if (!token) return thunkAPI.rejectWithValue("No token provided");
      return await assignmentService.lockUnlockAssignment(
        groupID,
        assignmentID,
        isUnlocked,
        token
      );
    } catch (error: any) {
      const message = error.response?.data?.message || error.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const assignmentSlice = createSlice({
  name: "assignments",
  initialState,
  // for syncronous functions
  reducers: {
    // reset to initial state
    reset: (state) => {
      state.assignments = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  // for async functions
  extraReducers: (builder) => {
    builder

      .addCase(getAssignments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssignments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.assignments = action.payload;
      })
      .addCase(getAssignments.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message =
          action.payload ?? "An error occurred in the Lecture Slice";
      })
      .addCase(lockUnlockAssignment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(lockUnlockAssignment.fulfilled, (state, action) => {
        const updatedAssignment = action.payload;
        state.isLoading = false;
        state.isSuccess = true;
        if (state.assignments) {
          state.assignments[updatedAssignment.id - 1] = updatedAssignment;
        }
      })
      .addCase(lockUnlockAssignment.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message =
          action.payload ?? "An error occurred in the Assignment Slice";
      });
  },
});

export const { reset } = assignmentSlice.actions;

export default assignmentSlice.reducer;
