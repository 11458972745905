import { FC } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Assignments from "./pages/Assignments";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import LecturePage from "./pages/LecturePage";
import Lectures from "./pages/Lectures";
import Login from "./pages/Login";
import PrivateRoutes from "./utils/PrivateRoutes";

const App: FC = () => {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />

          {/* Private Routes,  */}
          <Route path="/" element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route path="/lectures" element={<Lectures />} />
            <Route path="/lectures/:id" element={<LecturePage />} />
            <Route path="/assignments" element={<Assignments />} />
          </Route>

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
};

export default App;
